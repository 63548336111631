// validating the emails format
export function isEmailValid(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

// validating the url format
export function isUrlValid(str) {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return pattern.test(str);
}

// // getting the error from the server
// export function catchError(error, getError = false) {
//   const err = error?.data || error;
//   //  get the first message in the error array in case we have validation errors and message is not clear enough
//   if (getError) return Object.values(err.errors || err)[0] || err.message;
//   // if there is no err.message, getting the value of the first key in the object
//   return err.message || Object.values(err.errors || err)[0];
// }

export function catchError(error, prioritizeErrors = true) {
  // this is an improved version of the catchError function that can handle more complex error objects
  const err = error?.data || error;
  let errorMessages = [];

  let errorData = err.errors || err.error;
  if (errorData) {
    if (Array.isArray(errorData)) {
      // if the error is an array of strings or objects, get the message or stringify the object
      errorMessages = errorData.map(e => (typeof e === 'string' ? e : e.message || JSON.stringify(e)));
    } else if (typeof errorData === 'object') {
      // if the error is an object, get the values of the object and flatten them
      errorMessages = Object.values(errorData)
        .flat()
        .map(e => (typeof e === 'string' ? e : e.message || JSON.stringify(e)));
    }
  }

  if (err.message && (prioritizeErrors ? errorMessages.length === 0 : true)) {
    // if there is an error message and no other errors are found, add the error message
    errorMessages.push(err.message);
  }

  if (errorMessages.length === 0) {
    // if no error messages are found, get the error message or stringify the error
    if (typeof err === 'string') {
      errorMessages.push(err);
    } else if (typeof err === 'object') {
      const otherErrors = Object.values(err)
        .flat()
        .map(e => (typeof e === 'string' ? e : JSON.stringify(e)));
      if (otherErrors.length) {
        errorMessages.push(...otherErrors);
      } else {
        errorMessages.push(JSON.stringify(err));
      }
    }
  }

  // join the error messages and return the result
  const result = errorMessages.join('. ').trim();
  return result || 'Unknown error occurred';
}

// validating the video url and format
export const validateVideoRegexAndFormat = ({ passedVideoLink }) => {
  let videoFormatError = null;
  let videoFormat = null;

  const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
  const vimeoRegex = /^(https?\:\/\/)?(www\.)?(player\.)?vimeo\.com\/.+$/;
  const mp4Regex = /^(https?\:\/\/)?(www\.)?.+\.mp4$/;

  // no video link passed
  if (!passedVideoLink) {
    console.warn('No video link passed to validateVideoRegexAndFormat()');
    return {
      returnedVideoFormatError: null,
      returnedVideoFormat: null
    };
  }

  // invalid video link passed
  if (!youtubeRegex.test(passedVideoLink) && !vimeoRegex.test(passedVideoLink) && !mp4Regex.test(passedVideoLink)) {
    videoFormatError = 'Invalid video url, supported formats are Youtube, Vimeo & MP4 videos.';
  } else {
    videoFormatError = null;
  }

  // valid video link passed, determine the format
  if (youtubeRegex.test(passedVideoLink)) {
    videoFormat = 'youtube';
  } else if (vimeoRegex.test(passedVideoLink)) {
    videoFormat = 'vimeo';
  } else if (mp4Regex.test(passedVideoLink)) {
    videoFormat = 'mp4';
  }

  return {
    returnedVideoFormatError: videoFormatError,
    returnedVideoFormat: videoFormat
  };
};

export const isObjectEmpty = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
